import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import hawkeye from "../images/hawkeye-logo.png"

const ArtificialInt = () => (
  <Layout>
    <SEO title="Artificial Intelligence" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-orange">Artificial Intelligence</h1>
        <div className="row">
          <div className="col-lg-6 col-md-12 order-lg-1 order-md-1 order-1">
            <p>
              Tired of processing and sifting through all the data that your
              business record accumulate? Want to make the best out of these
              huge sets of information?
            </p>
            <p>
              Through Gray Hawk’s data extraction, machine learning, and
              artificial intelligence services - you can turn physical records
              into digital data and distributed datasets into cohesive reports.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 order-lg-2 order-md-3 order-3 mx-lg-auto mb-lg-5 text-center">
            <img src={hawkeye} className="img-fluid w-75" />
          </div>
          <div className="col-lg-6 col-md-12 order-lg-3 order-md-2 order-2 text-center mt-lg-0 mt-md-5 mt-5">
            <iframe
              title="Artificial Intelligence"
              width="100%"
              src="https://www.youtube.com/embed/vOvwJE8Xuwk"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                border: "2px solid #f9730b",
                borderRadius: "5px",
                height: "500px",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-12 order-lg-4 order-md-4 order-4 mx-auto mt-lg-0 mt-md-5 mt-5 mb-lg-0 mb-md-5 mb-5">
            <div className="img-fluid">
              <iframe
                width="100%"
                src="https://www.youtube.com/embed/oHzXzk96wHw"
                title="Hawkeye Powered by Grooper"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  border: "2px solid rgb(249, 115, 11)",
                  borderRadius: "5px",
                  height: "500px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-lg-5">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="my-3">Advanced Optical Character Recognition</h2>
          </div>
          <div className="col-lg-8">
            <p>
              Have a thorough understanding of your business documents and data
              with the help of OCR (Optical Character Recognition) technology.
            </p>
            <p>
              Since humans have to manually read and understand characters and
              words page by page, the process naturally bogs down. There are
              only so many working hours in a day, after all. In an environment
              where quick processing of information is required to maximize
              productivity and profitability, the delays can become a headache.
            </p>
            <p>
              With advanced OCR technology, your whole team can shed the burden
              of manual data extraction, sorting, and processing. This process
              involves high-level AI and computing power to mechanically and
              automatically organize all sorts of data.
            </p>
            <p>
              Whether the information is stored in files, spreadsheets,
              information systems, or repositories - intelligent document
              processing can be achieved.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="my-3">
              Automate Data Extraction
              <br />
              Using Artificial Intelligence
            </h2>
          </div>
          <div className="col-lg-10 mx-lg-auto my-3">
            <ul style={{ columns: "2" }}>
              <li>Better organization and curation</li>
              <li>Content tagging and named entities</li>
              <li>Convenient data streams to workflows</li>
              <li>Easy creation of data models</li>
              <li>Quick scanning of images and formatting</li>
              <li>Smart page identification and document classification</li>
              <li>Smooth file and information sorting</li>
              <li>Software application integrations</li>
              <li>And so much more</li>
            </ul>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  As our valued customer, you can easily extract lease data,
                  lessor and lessee information, dates, terms, acreage and legal
                  descriptions, contract details, special provisions, and other
                  important recorded information.
                </p>
                <p>
                  Go through surface contracts, easements and right-of-way
                  agreements to find the needed data to make intelligent
                  business decisions. You can even extract calls to GIS Maps and
                  process AFE and JIB Extractions.{" "}
                </p>
                <p>
                  Get the details like never before and make better decisions.
                  Push your business’ data extraction and sorting capabilities
                  to new limits through artificial intelligence. Piles of data
                  sheets and files, whether physical or digital, won’t be a
                  headache anymore.
                </p>
                <p>
                  To know more about our data extraction and artificial
                  intelligence solutions, send us an email at
                  <a
                    href="mailto:info@grayhawkland.com"
                    className="mx-1 text-orange"
                  >
                    info@grayhawkland.com
                  </a>
                  or reach us via this number:
                  <a href="tel:303.223.1665" className="mx-1 text-orange">
                    303.223.1665
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ArtificialInt
